import React, { Component } from 'react';
import moment from 'moment';
import Button from '../inputs/Button';
import Modal from '../Modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Pagination from '../../components/Pagination';

import './index.css';
const perPage = 10;
export default class Osha301List extends Component {
  state = {
    selected: 0,
    openPrintModal: false,
    page: 0,
    pageCases: []
  };

  componentDidMount = () => {
    let index = 0;
    const pageCases = this.props?.incidentReports?.slice(
      this.state.page * perPage,
      (this.state.page + 1) * perPage
    );
    this.setState({ pageCases });
    if (this.props.selectedRow.caseNumber && this.props.incidentReports) {
      index = this.props.incidentReports.findIndex(
        log => log.caseNumber === this.props.selectedRow.caseNumber
      );
      if (index === -1) index = 0;
    }
    this.selectNewCase(index);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const pageCases = this.props?.incidentReports?.slice(
      this.state.page * perPage,
      (this.state.page + 1) * perPage
    );

    if (prevProps.incidentReports !== this.props.incidentReports) {
      this.setState({
        pageCases,
        page: 0
      });
    }

    if (prevState.page !== this.state.page) {
      this.setState({
        pageCases,
        selected: 0
      });
    }
  };

  selectNewCase = index => {
    this.setState({ selected: index });
  };

  getRowClassNames = (index, isIncomplete) => {
    const selected = this.state.selected;

    if (selected === index) {
      return isIncomplete
        ? 'rowSelectedIncomplete isIncomplete'
        : 'rowSelectedComplete isComplete';
    } else {
      return isIncomplete ? 'isIncomplete' : 'isComplete';
    }
  };

  handleOpenModal = values => {
    this.setState(values);
  };

  handleCloseModal = values => {
    this.setState(values);
  };

  render() {
    const { setActiveIncident, printSelectedOsha, isPerrp } = this.props;

    const { openPrintModal, selected, page, pageCases } = this.state;

    const incidentReport =
      this.props.incidentReports[this.state.page * perPage + selected];
    const caseNumber = incidentReport?.caseNumber;
    const claimData = incidentReport?.claimData ?? {};
    const healthCareData = incidentReport?.healthCareData ?? {};
    let workerData = incidentReport?.workerData ?? {};
    if (workerData.privacyConcern) {
      workerData = {
        ...workerData,
        name: 'Privacy Concern Case',
        streetAddress: '------------------',
        city: '----------',
        state: '---',
        zip: '------',
        dateOfBirth: '--/--/--',
        dateOfHire: '--/--/--',
        gender: '------'
      };
    }

    return (
      <div className="osha301List">
        <div className="osha301List-caseList">
          <table className="osha301List-caseList--table">
            <thead>
              <tr>
                <th className="paddingBottom">Case #</th>
                <th className="leftAlign paddingBottom">Worker Name</th>
                <th className="leftAlign paddingBottom">Job Title</th>
              </tr>
            </thead>
            <tbody>
              {pageCases?.map((item, index) => {
                if (item.isRedLined) return <></>;
                return (
                  <tr
                    key={index}
                    className={this.getRowClassNames(index, item.isIncomplete)}
                    onClick={() => this.selectNewCase(index)}
                  >
                    <td className="osha301List-caseList--caseWidth">
                      {item.isIncomplete && (
                        <div
                          className="inCompleteIcon"
                          onMouseDown={() => setActiveIncident(item.incidentId)}
                        >
                          <ReactTooltip
                            className="oshaListErrorTheme"
                            id="inCompleteCaseError"
                            delayShow={130}
                          >
                            <p>{`Missing ${
                              isPerrp ? 'PERRP' : 'OSHA'
                            } compliant information.`}</p>
                            <p>Click to Go Edit Case </p>
                          </ReactTooltip>
                          <img
                            style={{ width: '32px', height: '32px' }}
                            src={require('../../assets/images/incompleteCase.png')}
                            alt="caseIncomplete"
                            data-tip="oshaListErrorTheme"
                            data-tooltip-id="inCompleteCaseError"
                          />
                        </div>
                      )}
                      {item.caseNumber}
                    </td>
                    <td className="osha301List-caseList--workerName leftAlign">
                      <div>
                        <ReactTooltip
                          className="tooltip"
                          id={`tooltipName${index}`}
                          delayShow={130}
                        >
                          <p>
                            {item.workerData.privacyConcern
                              ? 'Privacy Concern'
                              : item.workerData.name}
                          </p>
                        </ReactTooltip>
                      </div>
                      <span data-tip data-tooltip-id={`tooltipName${index}`}>
                        {item.workerData.privacyConcern
                          ? 'Privacy Concern'
                          : item.workerData.name}
                      </span>
                    </td>
                    <td className="osha301List-caseList--jobTitle leftAlign">
                      <div>
                        <ReactTooltip
                          className="tooltip"
                          id={`tooltipJobTitle${index}`}
                          delayShow={130}
                        >
                          <p>
                            {item.workerData.privacyConcern
                              ? '-----------------'
                              : item.workerData.jobTitle}
                          </p>
                        </ReactTooltip>
                      </div>
                      <span
                        data-tip
                        data-tooltip-id={`tooltipJobTitle${index}`}
                      >
                        {item.workerData.privacyConcern
                          ? '-----------------'
                          : item.workerData.jobTitle}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            page={page}
            count={this.props?.incidentReports?.length}
            perPage={perPage}
            setPage={page => this.setState({ page })}
          />
        </div>
        <div className="osha301List-caseInformation">
          <div className="osha301List-caseInformation--leftSide">
            <section className="osha301List-caseInformation--section">
              <h4>Worker Information</h4>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Name:
                </span>{' '}
                <div className="underline-fullWidth">{workerData.name}</div>
              </div>
              <div className="osha301List-caseInformation--sectionFieldNoBorder">
                <span className="osha301List-caseInformation--sectionLabel">
                  Street:
                </span>{' '}
                <div className="underline-fullWidth">
                  {workerData.streetAddress}
                </div>
                <div className="osha301List-caseInformation--fieldCombo">
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      City:
                    </span>{' '}
                    <div className="underline-cityWidth">{workerData.city}</div>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      State:
                    </span>{' '}
                    <span className="underline">
                      {workerData.state
                        ? workerData.state?.label || workerData.state
                        : ''}
                    </span>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      ZIP:
                    </span>{' '}
                    <span className="underline">{workerData.zip}</span>
                  </div>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <div className="osha301List-caseInformation--fieldCombo">
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      Date Of Birth:
                    </span>{' '}
                    <span className="underline">
                      {workerData.dateOfBirth !== '--/--/--' &&
                      workerData.dateOfBirth !== ''
                        ? moment(workerData.dateOfBirth).format('MM/DD/YYYY')
                        : workerData.dateOfBirth}
                    </span>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      Hired:
                    </span>{' '}
                    <span className="underline">
                      {workerData.dateOfHire !== '' &&
                      workerData.dateOfHire !== '--/--/--'
                        ? moment(workerData.dateOfHire).format('MM/DD/YYYY')
                        : workerData.dateOfHire}
                    </span>
                  </div>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Gender:{' '}
                </span>{' '}
                <span className="underline">{workerData.gender}</span>
              </div>
            </section>
            <section className="osha301List-caseInformation--section">
              <h4>Health Care Professional Information</h4>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Health Care Professional's Name:
                </span>{' '}
                <div className="underline-allWidth">
                  {healthCareData.doctorsName}
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionFieldNoBorder">
                <div style={{ marginBottom: '10px' }}>
                  <span className="osha301List-caseInformation--sectionLabel">
                    Facility:
                  </span>{' '}
                  <div className="underline-justUnderFullWidth">
                    {healthCareData.facilityName
                      ? healthCareData.facilityName
                      : 'N/A'}
                  </div>
                </div>
                <div>
                  <span className="osha301List-caseInformation--sectionLabel">
                    Street:
                  </span>{' '}
                  <div className="underline-fullWidth">
                    {healthCareData.streetAddress
                      ? healthCareData.streetAddress
                      : 'N/A'}
                  </div>
                </div>
                <div className="osha301List-caseInformation--fieldCombo">
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      City:
                    </span>{' '}
                    <div className="underline-cityWidth">
                      {healthCareData.city ? healthCareData.city : 'N/A'}
                    </div>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      State:
                    </span>{' '}
                    <span className="underline">
                      {healthCareData.state
                        ? healthCareData.state?.label || healthCareData.state
                        : 'N/A'}
                    </span>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      ZIP:
                    </span>{' '}
                    <span className="underline">
                      {healthCareData.zip ? healthCareData.zip : 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Emergency Room:
                </span>{' '}
                <span className="underline">
                  {healthCareData.isEmergencyRoom === 1 ? 'Yes' : 'No'}
                </span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Overnight as Inpatient:
                </span>{' '}
                <span className="underline">
                  {healthCareData.isOvernightAsInpatient === 1 ? 'Yes' : 'No'}
                </span>
              </div>
              {isPerrp && (
                <div className="osha301List-caseInformation--sectionField">
                  <span className="osha301List-caseInformation--sectionLabel">
                    Employee Received First Aid Treatment:
                  </span>{' '}
                  <span className="underline">
                    {healthCareData.employeeReceiveTreatment === 1
                      ? 'Yes'
                      : 'No'}
                  </span>
                </div>
              )}
            </section>
          </div>
          <div className="osha301List-caseInformation--rightSide">
            <section className="osha301List-caseInformation--section">
              <h4>Case Information</h4>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Case Number:
                </span>{' '}
                <div className="underline-caseNumberWidth">{caseNumber}</div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Date Of Injury or Illness:
                </span>{' '}
                <span className="underline">
                  {claimData.dateOfClaim !== ''
                    ? moment(claimData.dateOfClaim).format('MM/DD/YYYY')
                    : claimData.dateOfClaim}
                </span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Time worker began work:
                </span>{' '}
                <span className="underline">{claimData.timeWorkerBegan}</span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Time of Incident:
                </span>{' '}
                <span className="underline">
                  {claimData.timeNotDetermined
                    ? 'Cannot be determined'
                    : moment(claimData.dateOfClaim).format(
                        'MM/DD/YYYY hh:mm a'
                      )}
                </span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What was the worker doing prior to incident?
                </span>{' '}
                <div className="underline-allWidth">
                  <div>
                    <ReactTooltip className="tooltip" id="claimData">
                      <p>{claimData.activityBeforeIncident}</p>
                    </ReactTooltip>
                  </div>
                  <span data-tip data-tooltip-id="claimData">
                    {claimData.activityBeforeIncident}
                  </span>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What happened?
                </span>{' '}
                <div className="underline-allWidth">
                  <div>
                    <ReactTooltip className="tooltip" id="claimDesc">
                      <p> {claimData.descriptionOfClaim}</p>
                    </ReactTooltip>
                  </div>
                  <span data-tip data-tooltip-id="claimDesc">
                    {claimData.descriptionOfClaim}
                  </span>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What was illness or injury?
                </span>{' '}
                <div className="underline-allWidth">
                  {Array.isArray(claimData.injuryIllnessClassification)
                    ? claimData.injuryIllnessClassification.join(' ')
                    : ' '}
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What directly harmed the worker?
                </span>{' '}
                <div className="underline-allWidth">
                  {claimData.whatHarmedWorker}
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Date of Death:
                </span>{' '}
                <span className="underline">
                  {claimData.dateOfDeath
                    ? moment(claimData.dateOfDeath).format('L')
                    : 'N/A'}
                </span>
              </div>
            </section>
          </div>
        </div>
        <div className="osha301List-button--div">
          <Button
            text="Download Report"
            color="blue"
            className="osha301List-button"
            onClick={() =>
              incidentReport?.isIncomplete
                ? this.handleOpenModal({ openPrintModal: true })
                : printSelectedOsha({ incidentId: incidentReport?.incidentId })
            }
          />
        </div>

        <Modal
          title="Download Incident Report"
          titleClassName="redHeader"
          isOpen={openPrintModal}
          submitButtonColor="blue"
          submitButtonText="Download Incomplete Report"
          onRequestClose={() =>
            this.handleCloseModal({ openPrintModal: false })
          }
          submitActions={() =>
            printSelectedOsha({ incidentId: incidentReport?.incidentId }) &&
            this.handleCloseModal({ openPrintModal: false })
          }
        >
          <p className="osha301List-incompleteModal">
            {' '}
            This report is missing information required by OSHA. Are you sure
            you want to download it?
          </p>
        </Modal>
      </div>
    );
  }
}
